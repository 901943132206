import { Drawer } from '@mui/material';
import AddIcon from '@mui/icons-material/AddOutlined';
import styles from './AppNavigation.module.css';
import { Link } from 'react-router-dom';
import { useAppStore } from '../../../store/appStore';
import { DRAWER_WIDTH } from '../../../constants';
import classnames from 'classnames';
import { Typography, List, ListItem, ListItemButton, ListItemContent, ListDivider } from '@mui/joy';
import logo from '../../../assets/logo.png';
import SettingsIcon from '@mui/icons-material/Settings';
import { useFeatureParams } from '../../../common/useFeatureParam';

export function AppNavigation() {
  const activeProjectId = useAppStore(state => state.activeProjectId);

  const bootstrapInfo = useAppStore(state => state.bootstrapInfo);
  const { enableAutopilot } = useFeatureParams();

  return (
    <Drawer
      sx={{
        width: DRAWER_WIDTH,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: DRAWER_WIDTH,
          boxSizing: 'border-box',
        },
      }}
      PaperProps={{
        sx: {
          backgroundColor: '#272525',
          color: '#272525',
        },
      }}
      variant="permanent"
      anchor="left"
      className={styles.appNav}
    >
      <List>
        <ListItem className={styles.listItem}>
          <ListItemButton
            className={styles.listButton}
            to={'/'}
            component={Link}
            sx={{
              '&:hover': {
                backgroundColor: 'transparent !important',
              },
            }}
          >
            <ListItemContent>
              <Typography level="h3" sx={{ color: '#fff' }} className={styles.heading}>
                <img src={logo} alt="logo" className={styles.logo} />
                Arka
              </Typography>
            </ListItemContent>
          </ListItemButton>
        </ListItem>
        <ListDivider className={styles.divider} />
        <ListItem className={styles.listItem}>
          <ListItemButton
            to="/"
            component={Link}
            className={styles.listButton}
            sx={{
              '&:hover': {
                backgroundColor: '#4d4d4dfc !important',
              },
            }}
          >
            <ListItemContent>
              <Typography level="title-sm" sx={{ color: '#fff' }}>
                Create Project
              </Typography>
            </ListItemContent>
            <AddIcon sx={{ color: '#fff' }} />
          </ListItemButton>
        </ListItem>
        <ListDivider className={styles.divider} />
        {!enableAutopilot && (
          <ListItem className={styles.listItem}>
            <ListItemButton
              to="/setting"
              component={Link}
              className={styles.listButton}
              sx={{
                '&:hover': {
                  backgroundColor: '#4d4d4dfc !important',
                },
              }}
            >
              <ListItemContent>
                <Typography level="title-sm" sx={{ color: '#fff' }}>
                  Setting
                </Typography>
              </ListItemContent>
              <SettingsIcon sx={{ color: '#fff' }} />
            </ListItemButton>
          </ListItem>
        )}

        {bootstrapInfo?.projects && bootstrapInfo.projects.length > 0 && (
          <>
            <Typography
              level="title-sm"
              fontWeight={'lg'}
              fontSize={'xs'}
              sx={{ color: '#9e9a9a', padding: '12px' }}
              className={styles.projectsHeader}
            >
              Projects
            </Typography>
            {bootstrapInfo.projects.map(project => (
              <ListItem
                key={project.id}
                className={classnames(styles.listItem, styles.projectListItem, {
                  [styles.selectedListItem]: activeProjectId == project.id,
                })}
              >
                <ListItemButton
                  to={`/project/${project.id}`}
                  sx={{
                    '&:hover': {
                      backgroundColor: '#4d4d4dfc !important',
                    },
                  }}
                  component={Link}
                >
                  <ListItemContent>
                    <Typography
                      level="title-sm"
                      sx={{ color: '#ccc' }}
                      fontWeight={activeProjectId == project.id ? 'lg' : 'md'}
                    >
                      {project.name}
                    </Typography>
                  </ListItemContent>
                </ListItemButton>
              </ListItem>
            ))}
          </>
        )}
      </List>
    </Drawer>
  );
}
