import { useEffect, useState } from 'react';
import { useAppStore } from '../../../store/appStore';
import styles from './project.module.css';
import _ from 'lodash';
import { Drawer, Stack } from '@mui/material';
import { ProjectAssistant } from '../project-assistant/ProjectAssistant';
import { CriteriaForm } from '../criteria-form/CriteriaForm';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import { Typography, Button, ToggleButtonGroup, Box, Switch } from '@mui/joy';
import { SEARCH_CANDIDATES_PAGE_SIZE } from '../../candidate/slice/candidateSlice';
import { useScrollToTop } from '../../../common/useScrollToTop';
import { Candidate, PipelineStageEnum } from 'api-types';
import { SpinnerLoader } from '../../../components/Loader/SpinnerLoader';
import { CandidateList } from '../../candidate/CandidateList';
import { ProjectForm } from '../project-form/ProjectForm';
import { useSearchCandidatesFetcher } from '../../candidate/hooks/useSearchCandidatesFetcher';
import { useFeatureParams } from '../../../common/useFeatureParam';

export function ProjectSourceScreen() {
  const project = useAppStore(state => state.project);
  const batchUpdateCandidateStages = useAppStore(state => state.batchUpdateCandidateStages);
  const [projectDrawerView, setProjectDrawerView] = useState<'filter' | 'edit' | 'chat'>();
  const [shortListing, setShortlisting] = useState(false);
  const { candidates, handlePageChange, currentPage, loading, totalCount } =
    useSearchCandidatesFetcher();
  const projectCriteria = useAppStore(state => state.projectCriteria);
  const fetchProjectCriteria = useAppStore(state => state.fetchProjectCriteriaForm);
  const { enableAutopilot } = useFeatureParams();
  const [autopilot, setAutopilot] = useState(false);
  const handleShortlist = async (id: string) => {
    setShortlisting(true);
    try {
      await batchUpdateCandidateStages([
        {
          candidateId: id,
          stage: PipelineStageEnum.Shortlist,
        },
      ]);
    } finally {
      setShortlisting(false);
    }
  };

  const handleReject = async (id: string) => {
    setShortlisting(true);
    try {
      await batchUpdateCandidateStages([
        {
          candidateId: id,
          stage: PipelineStageEnum.Rejected,
        },
      ]);
    } finally {
      setShortlisting(false);
    }
  };

  const resetOnProjectCriteriaChange = async () => {
    await fetchProjectCriteria();
    handlePageChange(0);
  };

  const onDrawerClose = () => {
    setProjectDrawerView(undefined);
    resetOnProjectCriteriaChange();
  };

  const renderShortlistButton = (candidate: any) => {
    return (
      <Button
        size="sm"
        color={'neutral'}
        variant="outlined"
        onClick={() => handleShortlist(candidate.id)}
        disabled={shortListing}
      >
        Shortlist
      </Button>
    );
  };

  const renderRejectButton = (candidate: any) => {
    return (
      <Button
        size="sm"
        color={'neutral'}
        variant="outlined"
        onClick={() => handleReject(candidate.id)}
        disabled={shortListing}
      >
        Reject
      </Button>
    );
  };

  const renderActions = (candidate: Candidate) => {
    return (
      <div className={styles.actions}>
        {renderRejectButton(candidate)}
        {renderShortlistButton(candidate)}
      </div>
    );
  };

  const getDrawerView = () => {
    if (projectDrawerView === 'filter') {
      return <CriteriaForm />;
    } else if (projectDrawerView === 'chat') {
      return <ProjectAssistant />;
    } else if (projectDrawerView === 'edit') {
      return <ProjectForm project={project} />;
    }
  };

  return (
    <div>
      <div className={styles.header}>
        <Typography level="title-md" color="neutral" fontWeight="lg">
          {project?.name}
        </Typography>
        <Stack direction={'row'} spacing={0}>
          <Button color={'neutral'} variant="plain" onClick={() => setProjectDrawerView('filter')}>
            Filters
          </Button>

          <Button
            color={'neutral'}
            variant="plain"
            onClick={() => setProjectDrawerView('chat')}
            startDecorator={<AutoAwesomeOutlinedIcon />}
          >
            Assistant
          </Button>
        </Stack>
      </div>
      {enableAutopilot && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: 3 }}>
          <Typography
            level="title-sm"
            fontWeight="lg"
            endDecorator={<Switch variant="soft" onChange={() => setAutopilot(!autopilot)} />}
          >
            {autopilot ? 'Disable Autopilot' : 'Enable Autopilot'}
          </Typography>
        </Box>
      )}
      <div className={styles.candidateSearchContainer}>
        {loading ? (
          <SpinnerLoader />
        ) : candidates?.length ? (
          <CandidateList
            candidates={candidates || []}
            renderActions={renderActions}
            paginationConfig={{
              currentPage,
              totalCount: totalCount || 0,
              handlePageChange,
            }}
          />
        ) : (
          <Typography level="body-md" color="neutral">
            Candidate search is empty, please wait for more candidates to be added.
          </Typography>
        )}
      </div>
      <Drawer anchor={'right'} open={Boolean(projectDrawerView)} onClose={onDrawerClose}>
        <div className={styles.drawerContainer}>{getDrawerView()}</div>
      </Drawer>
    </div>
  );
}
