import { pascalCase } from 'change-case';
import moment from 'moment';

export function humanizeDateRange(startDate: string, endDate: string | undefined) {
  return `${moment(startDate).format('MMM YYYY')} - ${endDate ? moment(endDate).format('MMM YYYY') : 'Present'}`;
}

export function humanizeDegree(degree: string, fieldOfStudy: string | undefined) {
  return formatDegreeField(degree) + (fieldOfStudy ? ` in ${fieldOfStudy}` : '');
}

function formatDegreeField(degree: string) {
  const words = degree.split(' ');
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }
  return words.join(' ');
}
