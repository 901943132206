import { useState } from 'react';
import { batchUploadCandidates } from '../../../api/api';
import { PipelineStageEnum } from 'api-types';
import { Button, FormControl, FormLabel, Snackbar, Textarea, Typography } from '@mui/joy';
import styles from './candidate-recommdation.module.css';
import { useSnackbar } from '../../../components/Snackbar/SnackbarContext';
import { Input } from '../../../components/Input';

export function NewRecommendation({
  selectedUserId,
  selectedProjectId,
}: {
  selectedUserId: string;
  selectedProjectId: string;
}) {
  const [candidateDetails, setCandidateDetails] = useState<string>();
  const [linkedinUrl, setLinkedinUrl] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [candidateNotes, setCandidateNotes] = useState<string>();
  const [uploading, setUploading] = useState(false);
  const { showSuccessSnackbar } = useSnackbar();

  const uploadCandidate = async () => {
    if (!candidateDetails || !candidateNotes || !selectedUserId || !selectedProjectId) {
      return;
    }
    setUploading(true);
    await batchUploadCandidates({
      projectId: selectedProjectId,
      unstructuredCandidates: [
        {
          content: getCandidateContent(candidateDetails, email || '', linkedinUrl || ''),
          fileName: 'candidate-details.txt',
        },
      ],
      candidateStage: {
        stage: PipelineStageEnum.InternalDraft,
        notes: candidateNotes,
      },
    });
    showSuccessSnackbar('Candidate has been succesfully uploaded');
    setUploading(false);
  };

  const isButtonDisabled = !(
    linkedinUrl &&
    email &&
    candidateDetails &&
    candidateNotes &&
    selectedUserId &&
    selectedProjectId
  );

  return (
    <>
      <Typography level="title-md">Add New Candidate Recommendation</Typography>
      <Typography level="body-md">
        All uploaded candidates will be added as "internal-draft" stage
      </Typography>
      <div className={styles.formInput}>
        <FormControl>
          <FormLabel>Candidate LinkedIn Url</FormLabel>
          <Input size="lg" value={linkedinUrl} onChange={e => setLinkedinUrl(e.target.value)} />
        </FormControl>
      </div>
      <div className={styles.formInput}>
        <FormControl>
          <FormLabel>Candidate Email</FormLabel>
          <Input size="lg" value={email} onChange={e => setEmail(e.target.value)} />
        </FormControl>
      </div>
      <div className={styles.formInput}>
        <FormControl>
          <FormLabel>Candidate Details</FormLabel>
          <Textarea
            size="lg"
            value={candidateDetails}
            onChange={e => setCandidateDetails(e.target.value)}
            minRows={4}
          />
        </FormControl>
      </div>
      <div className={styles.formInput}>
        <FormControl>
          <FormLabel>Candidate Notes</FormLabel>
          <Textarea
            size="lg"
            value={candidateNotes}
            onChange={e => setCandidateNotes(e.target.value)}
            minRows={4}
          />
        </FormControl>
      </div>
      <Button
        color="primary"
        onClick={uploadCandidate}
        disabled={isButtonDisabled}
        loading={uploading}
      >
        Upload Candidate
      </Button>
    </>
  );
}

function getCandidateContent(candidateDetails: string, email: string, linkedinUrl: string) {
  if (!linkedinUrl) {
    throw 'LinkedIn URL is required to upload a candidate';
  }
  return `
    Email: ${email}
    LinkedIn: ${linkedinUrl}
    ${candidateDetails}
  `;
}
