import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import {
  Button,
  Card,
  DialogActions,
  DialogTitle,
  LinearProgress,
  Modal,
  ModalClose,
  ModalDialog,
  Typography,
} from '@mui/joy';
import { useCallback, useEffect, useState } from 'react';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import styles from './candidate-upload.module.css';
import { useDropzone } from 'react-dropzone';
import { extractTextFromFiles } from './pdfParser';
import { useAppStore } from '../../../store/appStore';
import classNames from 'classnames';
import { useSnackbar } from '../../../components/Snackbar/SnackbarContext';

export function CandidateUpload() {
  const [modalOpen, setModalOpen] = useState(false);
  const [uploadingCandidates, setUploadingCandidates] = useState(false);
  const batchUploadCandidates = useAppStore(state => state.batchUploadCandidates);
  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([]);
  const { showErrorSnackbar, showSuccessSnackbar } = useSnackbar();

  const onDrop = useCallback(
    (files: File[]) => {
      setAcceptedFiles([...acceptedFiles, ...files]);
      acceptedFiles.forEach(file => {});
    },
    [acceptedFiles]
  );

  const handleModalClose = () => {
    setAcceptedFiles([]);
    setModalOpen(false);
  };

  useEffect(() => {}, []);

  const handleCandidateUpload = async () => {
    setUploadingCandidates(true);
    try {
      const candidateFiles = await extractTextFromFiles(acceptedFiles);
      await batchUploadCandidates(candidateFiles);
      showSuccessSnackbar('Candidates have been succesfully uploaded');
    } catch (error) {
      console.error(error);
      showErrorSnackbar('Error uploading candidates');
    } finally {
      setAcceptedFiles([]);
      setUploadingCandidates(false);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    disabled: uploadingCandidates,
    accept: {
      'application/pdf': ['.pdf'],
    },
  });

  return (
    <>
      <Button variant="plain" color="neutral" onClick={() => setModalOpen(true)}>
        <FileUploadOutlinedIcon />
      </Button>

      <Modal open={modalOpen} onClose={handleModalClose}>
        <ModalDialog>
          <ModalClose />
          <DialogTitle>Upload Candidate</DialogTitle>
          <div className={styles.uploadContainer}>
            <div
              className={classNames(styles.fileUploadInputContainer, {
                'dropzone disabled': uploadingCandidates,
                [styles.disabledFileUploadInputContainer]: uploadingCandidates,
              })}
              {...getRootProps()}
            >
              <Card
                className={styles.card}
                color="neutral"
                variant={uploadingCandidates ? 'soft' : 'outlined'}
                size="lg"
              >
                <input {...getInputProps()} />
                <UploadFileOutlinedIcon
                  fontSize="large"
                  color="primary"
                  sx={{ fontSize: '40px', opacity: uploadingCandidates ? 0.5 : 1 }}
                />
              </Card>
            </div>
            <Typography level="body-md" gutterBottom>
              Drag and drop the candidate pdf or click to upload
            </Typography>
            <Typography level="body-xs" color="neutral" fontWeight="lg">
              Only pdf files are supported
            </Typography>
            {acceptedFiles?.length > 0 && (
              <div className={styles.selectedFiles}>
                <Typography level="title-sm" gutterBottom>
                  Selected Files
                </Typography>
                {acceptedFiles.map(file => (
                  <Typography key={file.name} level="body-xs">
                    {file.name}
                  </Typography>
                ))}
              </div>
            )}
          </div>
          {uploadingCandidates && <LinearProgress />}
          <DialogActions>
            <Button
              onClick={handleCandidateUpload}
              disabled={uploadingCandidates || acceptedFiles.length === 0}
            >
              Upload
            </Button>
            <Button onClick={handleModalClose}>Cancel</Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </>
  );
}
