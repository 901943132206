import { useEffect, useState } from 'react';
import styles from './candidate-recommdation.module.css';
import { batchUpdateCandidateStages } from '../../../api/api';
import { PipelineStageEnum } from 'api-types';
import { Button } from '@mui/joy';
import { useNavigate } from 'react-router-dom';
import { ADMIN_HOME_PATH, CANDIDATE_RECOMMENDATIONS_PATH_SECTION } from '../constants';
import { CandidatePipelineTable } from '../../outreach/pipeline/components/table/CandidatePipelineTable';
import { Drawer } from '@mui/material';
import { EditableCandidateProfile } from '../../candidate/profile/EditableCandidateProfile';
import { getCandidateFromCandidatePipeline } from '../../outreach/pipeline/utils';
import { useSnackbar } from '../../../components/Snackbar/SnackbarContext';
import { useCandidatesPipelineFetcherByProjectId } from '../../candidate/hooks/useCandidatesPipelineFetcher';

export function CandidateRecommendation({ selectedProjectId }: { selectedProjectId: string }) {
  const navigate = useNavigate();
  const [selectedCandidates, setSelectedCandidates] = useState<string[]>([]);
  const [openCandidateProfileId, setOpenCandidateProfileId] = useState('');
  const { showSuccessSnackbar } = useSnackbar();

  const { candidatePipeline, loading, refresh } = useCandidatesPipelineFetcherByProjectId(
    selectedProjectId,
    [PipelineStageEnum.ArkaRecommended, PipelineStageEnum.InternalDraft]
  );

  useEffect(() => {
    refresh();
  }, [selectedProjectId]);

  const onCandidateReasoningSubmit = async (candidateId: string, reasoningNotes: string) => {
    await batchUpdateCandidateStages({
      projectId: selectedProjectId!,
      candidateStageItems: [
        {
          candidateId,
          candidateStage: {
            stage: PipelineStageEnum.InternalDraft,
            notes: reasoningNotes,
          },
        },
      ],
    });
    showSuccessSnackbar('Candidate notes updated successfully');
    await refresh();
  };

  const handleNewRecommendation = () => {
    navigate(`${ADMIN_HOME_PATH}/${CANDIDATE_RECOMMENDATIONS_PATH_SECTION}/new`);
  };

  const handlePublish = async () => {
    if (selectedCandidates.length === 0) {
      return;
    }

    await batchUpdateCandidateStages({
      projectId: selectedProjectId!,
      candidateStageItems: selectedCandidates.map(candidateId => ({
        candidateId,
        candidateStage: {
          stage: PipelineStageEnum.ArkaRecommended,
        },
      })),
    });
    showSuccessSnackbar('Candidates published successfully');
    await refresh();
  };

  const openedCandidate = getCandidateFromCandidatePipeline(
    candidatePipeline,
    openCandidateProfileId
  );

  return (
    <>
      <div className={styles.newRecommendationBtn}>
        <Button onClick={handlePublish} disabled={selectedCandidates.length === 0}>
          Publish
        </Button>
        <Button onClick={handleNewRecommendation}>Add new recommendation</Button>
      </div>
      <>
        <CandidatePipelineTable
          candidatePipeline={candidatePipeline}
          onCandidateProfileOpen={id => setOpenCandidateProfileId(id)}
          tableConfig={{
            onCandidateSelection: (selectedCandidateIds: string[]) =>
              setSelectedCandidates(selectedCandidateIds),
          }}
        />
        <Drawer
          anchor={'right'}
          open={Boolean(openCandidateProfileId)}
          onClose={() => setOpenCandidateProfileId('')}
        >
          <div className={styles.drawerContainer}>
            {openedCandidate && (
              <EditableCandidateProfile
                candidate={openedCandidate}
                onCandidateReasoningSubmit={(notes: string) =>
                  onCandidateReasoningSubmit(openCandidateProfileId, notes)
                }
                projectId={selectedProjectId}
                renderReasoning
              />
            )}
          </div>
        </Drawer>
      </>
    </>
  );
}
