import { useRef } from 'react';

export function useScrollToTop() {
  function scrollToTop(behavior?: ScrollBehavior) {
    window.scrollTo({
      top: 0,
      behavior,
    });
  }
  return { scrollToTop };
}
