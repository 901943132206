import { StoreApi } from 'zustand';
import { AppStore } from '../../../store/types';
import { OutreachSlice, OutreachCandidate } from './types';
import {
  batchGetCandidateOutreachStats,
  batchGetCandidateOutreachThreads,
  bulkSendOutreach,
  generateOutreachMessage,
  getAllOutreachMessages,
  getAllOutreachPhoneConversations,
  getOutreach,
  upsertOutreach,
} from '../../../api/api';
import { Outreach, OutreachStage } from 'api-types';

const initState: Partial<OutreachSlice> = {
  outreach: undefined,
  candidateOutreachThreads: undefined,
  candidateOutreachStats: undefined,
};

export const createOutreachSlice = (
  set: StoreApi<OutreachSlice>['setState'],
  get: StoreApi<AppStore>['getState']
): OutreachSlice => ({
  ...initState,
  resetOutreachSlice() {
    set({
      ...initState,
    });
  },
  upsertOutreach: async (outreach: Outreach) => {
    const { activeProjectId } = get();
    if (!activeProjectId) {
      return;
    }
    const { outreach: newOutreach } = await upsertOutreach({
      outreach,
      projectId: activeProjectId,
    });
    const { activeProjectId: latestActiveProjectId } = get();
    if (latestActiveProjectId !== activeProjectId) {
      return;
    }
    set({ outreach: newOutreach });
  },
  fetchOutreach: async () => {
    const { activeProjectId } = get();
    if (activeProjectId) {
      const { outreach, projectId } = await getOutreach({ projectId: activeProjectId });
      const { activeProjectId: latestActiveProjectId } = get();
      if (latestActiveProjectId !== activeProjectId) {
        return;
      }
      set({ outreach });
    }
  },
  generateOutreachMessage: async (messageConfig, callbacks) => {
    const { activeProjectId } = get();
    if (activeProjectId) {
      generateOutreachMessage(
        {
          outreachMessageConfig: messageConfig,
          projectId: activeProjectId,
        },
        callbacks
      );
    }
  },
  bulkSendOutreach: async (candidateIds: string[]) => {
    const { activeProjectId } = get();
    if (activeProjectId) {
      await bulkSendOutreach({
        projectId: activeProjectId,
        candidateIds,
      });
    }
  },
  batchGetCandidateOutreachStats: async (candidateIds: string[] | undefined) => {
    const { activeProjectId } = get();
    if (activeProjectId && candidateIds?.length) {
      const { candidateOutreachStats } = await batchGetCandidateOutreachStats({
        projectId: activeProjectId,
        candidateIds,
      });
      const { activeProjectId: latestActiveProjectId } = get();
      if (latestActiveProjectId !== activeProjectId) {
        return;
      }
      set({ candidateOutreachStats });
    }
  },
  getAllOutreachMessages: async (candidateId: string) => {
    const { activeProjectId } = get();
    if (activeProjectId) {
      const { outreachMessages } = await getAllOutreachMessages({
        projectId: activeProjectId,
        candidateId,
      });
      const { activeProjectId: latestActiveProjectId } = get();
      if (latestActiveProjectId !== activeProjectId) {
        return;
      }
      return outreachMessages;
    }
  },
  batchGetCandidateOutreachThread: async (candidateIds: string[]) => {
    const { activeProjectId } = get();
    if (activeProjectId) {
      const { candidateOutreachThreads: outreachThreads } = await batchGetCandidateOutreachThreads({
        projectId: activeProjectId,
        candidateIds,
      });
      const { activeProjectId: latestActiveProjectId } = get();
      if (latestActiveProjectId !== activeProjectId) {
        return;
      }
      set({ candidateOutreachThreads: outreachThreads });
    }
  },
  getAllOutreachPhoneConversations: async (candidateId: string) => {
    const { activeProjectId } = get();
    if (activeProjectId) {
      const { phoneConversations } = await getAllOutreachPhoneConversations({
        projectId: activeProjectId,
        candidateId,
      });
      return phoneConversations;
    }
    return undefined;
  },
});
