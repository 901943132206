import { useEffect, useState } from 'react';
import { useAppStore } from '../../../store/appStore';
import { SEARCH_CANDIDATES_PAGE_SIZE } from '../slice/candidateSlice';
import { useScrollToTop } from '../../../common/useScrollToTop';
import { searchCandidatesByProject } from '../../../api/api';
import { Candidate } from 'api-types';

export function useSearchCandidatesFetcher() {
  const searchCandidates = useAppStore(state => state.searchCandidates);
  const searchCandidateResult = useAppStore(state => state.searchCandidateResult);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const { scrollToTop } = useScrollToTop();
  const fetchProjectCriteria = useAppStore(state => state.fetchProjectCriteriaForm);

  const fetchCandidatesForPage = async (pageNumber: number) => {
    const offset = pageNumber * SEARCH_CANDIDATES_PAGE_SIZE;
    setLoading(true);
    try {
      await searchCandidates({ offset });
      scrollToTop();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCandidatesForPage(0);
  }, []);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    fetchCandidatesForPage(pageNumber);
  };

  const refresh = () => fetchCandidatesForPage(currentPage);

  return {
    candidates: searchCandidateResult?.candidates,
    handlePageChange,
    currentPage,
    loading,
    totalCount: searchCandidateResult?.totalCount,
    refresh,
  };
}

export function useSearchCandidatesFetcherByProjectId(projectId: string) {
  const [searchCandidates, setSearchCandidates] = useState<Candidate[]>();
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const { scrollToTop } = useScrollToTop();

  const fetchCandidatesForPage = async (pageNumber: number, refresh?: boolean) => {
    const offset = pageNumber * SEARCH_CANDIDATES_PAGE_SIZE;
    setLoading(true);
    try {
      const { candidates } = await searchCandidatesByProject({
        projectId,
        offset,
        size: SEARCH_CANDIDATES_PAGE_SIZE,
      });
      scrollToTop();
      setSearchCandidates(candidates);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCandidatesForPage(0);
  }, [projectId]);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    fetchCandidatesForPage(pageNumber);
  };

  const refresh = () => fetchCandidatesForPage(currentPage, true);

  return { candidates: searchCandidates, handlePageChange, currentPage, refresh, loading };
}
