import { useMemo, useState } from 'react';
import { CandidateProfile } from '../../../candidate/profile/CandidateProfile';
import styles from './pipeline-table.module.css';
import { CandidatePipelineTable } from './table/CandidatePipelineTable';
import { CandidateOutreachStats, CandidatePipeline, Outreach, OutreachThread } from 'api-types';
import { Drawer } from '@mui/material';
import { getCandidateFromCandidatePipeline } from '../utils';

export function CandidatePipelineView(props: {
  candidatePipeline?: CandidatePipeline;
  candidateOutreachStats?: CandidateOutreachStats[];
  outreach?: Outreach;
  candidateOutreachThreads?: OutreachThread[];
  onCandidateProfileOpen?: (candidateId: string) => void;
}) {
  const [openCandidateProfileId, setOpenCandidateProfileId] = useState('');
  const openedCandidate = getCandidateFromCandidatePipeline(
    props.candidatePipeline,
    openCandidateProfileId
  );

  return (
    <>
      <CandidatePipelineTable
        {...props}
        onCandidateProfileOpen={id => setOpenCandidateProfileId(id)}
      />
      <Drawer
        anchor={'right'}
        open={Boolean(openCandidateProfileId)}
        onClose={() => setOpenCandidateProfileId('')}
      >
        <div className={styles.drawerContainer}>
          {openedCandidate && (
            <CandidateProfile candidate={{ candidate: openedCandidate }} renderMessages />
          )}
        </div>
      </Drawer>
    </>
  );
}
