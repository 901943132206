import { useState } from 'react';

interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  fallback: React.JSX.Element | null;
}

export function Image({ src, alt, fallback = null, ...rest }: ImageProps) {
  const [error, setError] = useState(false);

  const handleError = () => {
    setError(true);
  };

  return error ? (
    fallback
  ) : (
    <img src={src} alt={alt} width={16} height={16} onError={handleError} {...rest} />
  );
}
