import styles from './criteria-form.module.css';
import { useAppStore } from '../../../store/appStore';
import { useEffect, useState } from 'react';
import { AutoComplete } from '../../../components/AutoComplete';
import { ProjectCriteriaValues } from 'api-types';
import {
  Typography,
  Button,
  FormControl,
  FormLabel,
  Autocomplete as JoyAutocomplete,
} from '@mui/joy';
import { Textarea } from '../../../components/TextArea';
import { SpinnerLoader } from '../../../components/Loader/SpinnerLoader';
import { useSnackbar } from '../../../components/Snackbar/SnackbarContext';

export function CriteriaForm() {
  const criteria = useAppStore(state => state.projectCriteria);
  const { formSchema, values } = criteria || {};
  const { candidateCriteria, companyCriteria, educationCriteria } = values || {};
  const [jobTitles, setJobTitles] = useState(candidateCriteria?.jobTitles || []);
  const [yearsOfExperiences, setYearsOfExperiences] = useState(
    candidateCriteria?.yearsOfExperiences || []
  );
  const [locations, setLocations] = useState(candidateCriteria?.locations || []);
  const [skills, setSkills] = useState(candidateCriteria?.skills || []);
  const [companyNames, setCompanyNames] = useState(companyCriteria?.companyNames || []);
  const [companySizes, setCompanySizes] = useState(companyCriteria?.companySizes || []);
  const [companyFundingStages, setCompanyFundingStages] = useState(
    companyCriteria?.companyFundingStages || []
  );
  const [degrees, setDegrees] = useState(educationCriteria?.degrees || []);
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);

  const { showSuccessSnackbar } = useSnackbar();

  const updateProjectCriteria = useAppStore(state => state.updateProjectCriteria);
  const fetchProjectCriteria = useAppStore(state => state.fetchProjectCriteriaForm);

  const handleSubmit = async () => {
    const draftCriteria: ProjectCriteriaValues = {
      candidateCriteria: {
        jobTitles,
        yearsOfExperiences,
        locations,
        skills,
      },
      companyCriteria: {
        companyNames,
        companySizes,
        companyFundingStages,
      },
      educationCriteria: {
        degrees,
      },
    };
    try {
      setUpdating(true);
      await updateProjectCriteria(draftCriteria);
      showSuccessSnackbar('Project criteria updated successfully');
    } finally {
      setUpdating(false);
    }
  };

  const getProjectCriteria = async () => {
    try {
      setLoading(true);
      const projectCriteriaForm = await fetchProjectCriteria();
      const { candidateCriteria, companyCriteria, educationCriteria } =
        projectCriteriaForm?.values || {};
      setJobTitles(candidateCriteria?.jobTitles || []);
      setYearsOfExperiences(candidateCriteria?.yearsOfExperiences || []);
      setSkills(candidateCriteria?.skills || []);
      setLocations(candidateCriteria?.locations || []);
      setDegrees(educationCriteria?.degrees || []);
      setCompanyFundingStages(companyCriteria?.companyFundingStages || []);
      setCompanyNames(companyCriteria?.companyNames || []);
      setCompanySizes(companyCriteria?.companySizes || []);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProjectCriteria();
  }, []);

  return loading ? (
    <SpinnerLoader />
  ) : (
    <div className={styles.editProjectContainer}>
      <div className={styles.semiHeading}>
        <Typography level="h4">Who are you looking for?</Typography>
      </div>
      <div className={styles.formInput}>
        <FormControl>
          <FormLabel>Job Titles</FormLabel>
          <Textarea
            className={styles.textField}
            value={jobTitles.join(', ')}
            color="neutral"
            placeholder='e.g. "Software Engineer, Sr Software Engineer"'
            onChange={e => setJobTitles([e.target.value])}
          />
        </FormControl>
      </div>
      <div className={styles.formInput}>
        <FormControl>
          <FormLabel>Skills</FormLabel>
          <JoyAutocomplete<object, true, true, true>
            value={skills}
            multiple
            freeSolo
            onChange={(e, newValue) => setSkills(newValue as string[])}
            options={[]}
          />
        </FormControl>
      </div>
      <div className={styles.formInput}>
        <AutoComplete
          label="Location"
          value={locations}
          isMultiple
          onChange={value => setLocations(value as string[])}
          options={formSchema?.locationOptions || []}
        />
      </div>
      <div className={styles.formInput}>
        <AutoComplete
          label="Years of experience"
          value={yearsOfExperiences}
          isMultiple
          onChange={value => setYearsOfExperiences(value as string[])}
          options={formSchema?.yearsOfExperienceOptions || []}
        />
      </div>
      <div className={styles.formInput}>
        <AutoComplete
          label="Company Funding Stage"
          value={companyFundingStages}
          isMultiple
          onChange={value => setCompanyFundingStages(value as string[])}
          options={formSchema?.companyFundingStageOptions || []}
        />
      </div>
      <div className={styles.formInput}>
        <AutoComplete
          label="Company Size"
          value={companySizes}
          isMultiple
          onChange={value => setCompanySizes(value as string[])}
          options={formSchema?.companySizeOptions || []}
        />
      </div>
      <div className={styles.formInput}>
        <FormControl>
          <FormLabel>Company Names</FormLabel>
          <Textarea
            className={styles.textField}
            value={companyNames}
            onChange={e => setCompanyNames([e.target.value])}
            placeholder="e.g. Google, Microsoft"
          />
        </FormControl>
      </div>
      <div className={styles.formInput}>
        <AutoComplete
          label="Degrees"
          value={degrees}
          isMultiple
          onChange={value => setDegrees(value as string[])}
          options={formSchema?.degreeOptions || []}
        />
      </div>

      <div className={styles.editProjectActionBar}>
        <Button onClick={handleSubmit} disabled={updating}>
          Submit
        </Button>
      </div>
    </div>
  );
}
