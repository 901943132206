"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PipelineStageEnum = void 0;
var PipelineStageEnum;
(function (PipelineStageEnum) {
    PipelineStageEnum["Rejected"] = "Rejected";
    PipelineStageEnum["Starred"] = "Starred";
    PipelineStageEnum["Shortlist"] = "Shortlist";
    PipelineStageEnum["OutreachInitialized"] = "OutreachInitialized";
    PipelineStageEnum["OutreachCompleted"] = "OutreachCompleted";
    PipelineStageEnum["ArkaRecommended"] = "ArkaRecommended";
    PipelineStageEnum["InternalDraft"] = "InternalDraft";
})(PipelineStageEnum || (exports.PipelineStageEnum = PipelineStageEnum = {}));
