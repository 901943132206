import { Chip as MUIChip, ChipProps as MuiChipProps } from '@mui/joy';
import { MouseEventHandler } from 'react';

interface ChipProps extends MuiChipProps {
  backgroundColor?: 'yellow' | 'green' | 'gray' | undefined;
  onClick?: () => void;
}

const GREEN_COLOR = 'rgb(219, 237, 219)';
const GRAY_COLOR = 'rgb(227, 226, 224)';
const YELLOW_COLOR = 'rgb(253, 236, 200)';

export function Chip(props: ChipProps) {
  const { backgroundColor, onClick, ...rest } = props;
  const getBackgroundColor = () => {
    if (backgroundColor === 'green') {
      return GREEN_COLOR;
    }
    if (backgroundColor === 'gray') {
      return GRAY_COLOR;
    }
    if (backgroundColor === 'yellow') {
      return YELLOW_COLOR;
    }
  };
  return (
    <div onClick={e => onClick?.()}>
      <MUIChip
        {...rest}
        sx={{ backgroundColor: getBackgroundColor(), cursor: onClick ? 'pointer' : 'default' }}
      />
    </div>
  );
}
