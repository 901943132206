import { Typography, Link as LinkComponent } from '@mui/joy';
import styles from '../login/login.module.css';
import { GoogleButton } from '../login/google-btn/GoogleButton';

export function Setting() {
  const handleSetting = () => {
    window.open(`/sso/google/gmail`, '_self');
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.formContainer}>
          <Typography level="h1" sx={{ color: '#fff', marginBottom: '20px' }}>
            Would you like to give access to your Gmail account?
          </Typography>
          <GoogleButton handleClick={handleSetting} />
        </div>
      </div>
    </>
  );
}
